import React from "react";
import type { HeadFC } from "gatsby";
import { Maestro } from "../../screens/OverviewScreen";

const MaestroBrandPage = () => {
    return <Maestro />;
};

export default MaestroBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
